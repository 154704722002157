import React, { useState, useEffect } from 'react';
import nrutyamStyles from './nrutyamStyles';
import nrutyamImage from '../assets/images/nrutyam.jpeg'
import nrutyamImage2 from '../assets/images/nrutyam2.jpeg'

const Nrutyam = () => {
    const classes = nrutyamStyles();

    const openForm = () => {
        window.open('https://forms.gle/jN4LdFkor99qADE9A', '_blank')
    }

    return <>
        <div className={classes.nrutyamMain}>
            <div className={classes.nrutyamImageDiv}>
                <img src={nrutyamImage} className={classes.nrutyamImage} alt="jbgImage" />
            </div>
            <div className={classes.nrutyamImageDiv}>
                <img src={nrutyamImage2} className={classes.nrutyamImage} alt="jbgImage" />
            </div>
            <div>
                <button className={classes.nrutyamButton} onClick={openForm}>Register</button>
            </div>
        </div>
    </>
}

export default Nrutyam
