import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Box, Typography, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, TextField, Button,
    Checkbox, Slide
} from '@mui/material';
import 'react-calendar/dist/Calendar.css';
import { toast } from 'react-toastify';
import Calendar from 'react-calendar';
import useStyles from './useStyles';
import db from '../firebaseConfig';

const Ayambil = () => {
    const history = useHistory()
    const classes = useStyles();
    const [value, setValue] = useState(new Date());
    const [open, setOpen] = useState(false);
    const [disableDates, setdisableDates] = useState([]);
    const [removeDisableTilefeature, setremoveDisableTilefeature] = useState(false)
    const [opensuccess, setopensuccess] = useState(false)
    const [date, SetDate] = useState("")

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const getDates = async () => {
        await db.collection('Ayambil').get()
            .then((result) => {
                if (result.docs.length > 354) {
                    setremoveDisableTilefeature(true)
                }
                var dates = [];
                result.docs.forEach((element) => {
                    var date = element.id.split('-')[0]
                    var month = element.id.split('-')[1]
                    var year = element.id.split('-')[2]
                    dates.push(new Date(`${year}-${month}-${date}`))
                })
                setdisableDates([...dates])
            });
    }

    useEffect(() => {
        getDates()
    }, []);

    const handleClose = (data) => {
        setOpen(false);
    };
    const handleSuccess = () => {
        setopensuccess(false)
        history.push('/')
    }

    const onClickDay = (nextValue) => {
        setValue(nextValue);
        setOpen(true);
    }

    const isDateInArray = (array, value) => {
        return !!array.find(item => {
            return `${item.getDate()}-${item.getMonth() + 1}-${item.getFullYear()}` === `${value.getDate()}-${value.getMonth() + 1}-${value.getFullYear()}`
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (removeDisableTilefeature) {
            let data = {
                name: event.target.name.value,
                whatsapp: event.target.whatsapp.value,
                location: event.target.location.value,
                email: event.target.email.value,
                day: `${value.getDate()}-${value.getMonth() + 1}-${value.getFullYear()}`,
            }
            db.collection('AyambilOther').add(data).then(async (results) => {
                SetDate(data.day)
                await getDates()
                setOpen(false)
                setopensuccess(true)
            }).catch((err) => {
                toast.error(`Error! please Try again Later `, {
                    position: toast.POSITION.TOP_CENTER
                })
            })
        } else {
            let email = event.target.email.value;
            let user_data = {
                [email]: [{
                    name: event.target.name.value,
                    whatsapp: event.target.whatsapp.value,
                    location: event.target.location.value
                }]
            }
            let day = `${value.getDate()}-${value.getMonth() + 1}-${value.getFullYear()}`;

            const userRef = db.collection('Ayambil').doc(`${day}`);
            userRef.get()
                .then((result) => {
                    if (result.exists) {
                        toast.error(`Ayambil is already confirmed  on ${day} , Please select another date.. `, {
                            position: toast.POSITION.TOP_CENTER
                        })
                        setopensuccess(true)
                        // history.push('/Ayambil')
                    } else {
                        userRef.set(user_data);
                        SetDate(day)
                        setopensuccess(true)
                    }
                }).catch(() => {
                    toast.error(`Ayambil is already confirmed  on ${day} , Please select another date.. `, {
                        position: toast.POSITION.TOP_CENTER
                    })
                })
            await getDates()
            setOpen(false);
            setopensuccess(true)
        }
    }

    return (
        <Box className={classes.calendarWrapper} display="flex" flexDirection="column" justifyContent="center" mt={10}>
            <Box m="auto">
                <Typography align="center" variant="h5" fontWeight="700" gutterBottom className={classes.heroText}>આયંબિલ વિધિ</Typography>
                <Typography variant="body" fontWeight="700" gutterBottom className={classes.heroText}><ol  >
                    <li >૧૨ ખમાસમણા</li>
                    <li>નવકારવાળી नमो जिणाणं जिअ भयाणं — ૧ માળા</li>
                    <li>વિશ્વ હિંસા મુક્ત બને — ૧ માળા</li></ol></Typography>
                <Typography variant="body" fontWeight="700" gutterBottom className={classes.heroText} >નોંધ - વર્ધમાનતપની ઓળીના આયંબિલ આ આરાધનામાં ગણવા નહીં.</Typography>
            </Box>

            <Box display="flex" flexDirection="row" mt={7} justifyContent="center">
                {/* <Box className="actualCalendar" display="flex" justifyContent="flex-end"> */}
                <Calendar
                    minDate={removeDisableTilefeature ? new Date() : new Date('2021-12-13')}
                    maxDate={new Date('2022-12-2')}
                    value={value}
                    onClickDay={onClickDay}
                    tileDisabled={disableDates.length > 0 && !removeDisableTilefeature ? ({ date }) => isDateInArray(disableDates, date) : () => false}
                    tileContent={disableDates.length > 0 && !removeDisableTilefeature ? ({ date }) => isDateInArray(disableDates, date) && <p style={{ fontSize: '10px', margin: 0, padding: 0 }}>Ayambil Booked</p> : () => false}
                />
            </Box>

            <Typography mt={5} align="center" variant="body" fontWeight="700" gutterBottom className={classes.heroText}>"નોંધ : ફોનથી અઠ્ઠમ અને આયંબિલની સાંકળી આરાધનામાં જોડાવા સંપર્ક કરો - ૭૦૪૩૧૭૨૨૮૭"<br /></Typography>
            <br />

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Register</DialogTitle>
                <form id="user-form" onSubmit={handleSubmit}>
                    <DialogContent>
                        <DialogContentText>
                            Please in the details for successfull registration!!
                        </DialogContentText>
                        <TextField autoFocus margin="dense" id="name" name="name" label="Name" type="text" fullWidth variant="standard" required />
                        <TextField margin="dense" id="email" name="email" label="Email" type="email" fullWidth variant="standard" required />
                        <TextField margin="dense" id="whatsapp" inputProps={{ inputMode: 'numeric', pattern: '[0-9]{10}' }} name="whatsapp" label="Whatsapp" type="text" pattern="[0-9]{10}" fullWidth variant="standard" required />
                        <TextField margin="dense" id="location" name="location" label="Location" type="text" fullWidth variant="standard" required />
                        <Checkbox required /><label>I pledge to take part in the Ayambil</label>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Register</Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog
                open={opensuccess}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Thank you for your Registration"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Your Ayambil is Booked Successfully on <b>{date}</b>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSuccess}>OK</Button>
                </DialogActions>
            </Dialog>
            {/* maintenance break code */}
            {/* <Dialog
                open={true}
                TransitionComponent={Transition}
                keepMounted
                // onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"We'll be back soon!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Sorry for the inconvenience but we&rsquo;re performing some maintenance for booking Ayambil & Attham at the moment. we&rsquo;ll be back Tomorrow!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSuccess}>OK</Button>
                </DialogActions>
            </Dialog> */}
            {/* end of maintenance break code */}
        </Box>
    )
}

export default Ayambil;
