import React, { useState, useEffect } from 'react';
import kbgStyles from './kbgStyles';
import kbgImage from '../assets/images/kbg.jpeg'

const Kbg = () => {
    const classes = kbgStyles();

    const openForm = () => {
        window.open('https://forms.gle/FD2Lsg1Sd4TDfFwP8', '_blank')
    }

    const downloadPatrika = () => {
        window.open('https://jainsangh.s3.ap-south-1.amazonaws.com/hemsuriFiles/kbg.pdf', '_blank')
    }

    return <>
        <div className={classes.kbgMain}>
            <div className={classes.kbgImageDiv}>
                <img src={kbgImage} className={classes.kbgImage} alt="jbgImage" />
            </div>
            <div className={classes.kbgContent}>
                <div className={classes.kbgContentCenterDiv}>
                    <h2 className={classes.kbgContentH2}>સમાધિ પ્રતિષ્ઠા પર્વોત્સવ</h2>
                    <h1 className={classes.kbgContentH1}>KBG - Kaun Banega Gyani</h1>
                    <p className={classes.kbgContentP}>આ પ્રતિયોગિતામાં ભાગ લેવા માટે રજીસ્ટ્રેશન કરાવવું જરૂરી છે. તેના માટે નીચે આપેલા રજીસ્ટર બટન પર ક્લીક કરો. ફોર્મમાં આપેલી તમામ વિગતો ૨૦/૧૧/૨૦૨૨ રવીવાર સુધીમાં ભરી વહેલા તે પેહલા ધોરણે સબમિટ કરી આપનું નામ કૌન બનેગા જ્ઞાનીમાં નૌધણી કરો. તથા નીચે આપેલી માહિતી મુજબ આપની તૈયારી આજથી જ ચાલુ કરો.</p>
                    <h1 className={classes.kbgContentH1}>Kaun Banega Gyani</h1>
                    <div>
                        <button className={classes.kbgContentButton} onClick={openForm}>Register</button>
                        <button className={classes.kbgContentButton} onClick={downloadPatrika}>View Pdf</button>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Kbg
