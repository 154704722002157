import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    calendarWrapper: {
        width: '80%',
        height: '100%',
        margin: 'auto',
    },
    animationWrapper: {
        transform: 'rotateY(180deg)',
    },
    actualCalendar: {
        position: 'absolute',
    },
    heroText: {
        fontFamily: 'Hind Vadodara!important',
    },
    count: {
        width: '5px'
    }

}));

export default useStyles;
