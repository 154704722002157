import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';

import arihantStyles from './arihantStyles';

import arihant1 from '../assets/audio/arihant1.mpeg'
import arihant2 from '../assets/audio/arihant2.mpeg'

const Arihant = () => {
    const classes = arihantStyles();

    return <>
        <Box>
            <div className={classes.main} >
                <div>
                    <h3>
                        Arihant
                    </h3>
                </div>
                <div className={classes.list}>
                    <div className={classes.listEach}>
                        <audio controls>
                            <source src={arihant1} type="audio/mpeg" />
                        </audio>
                    </div>
                    <div>
                        <audio controls>
                            <source src={arihant2} type="audio/mpeg" />
                        </audio>
                    </div>
                </div>
            </div>

        </Box>
    </>
}

export default Arihant
