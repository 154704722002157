import firebase from 'firebase/compat/app';
import "firebase/compat/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyChy-Kw-ShLwPfshOOvUa5kOyJsEGzZ5f4",
    authDomain: "hemsuri-59b05.firebaseapp.com",
    projectId: "hemsuri-59b05",
    storageBucket: "hemsuri-59b05.appspot.com",
    messagingSenderId: "724443727632",
    appId: "1:724443727632:web:d83f053984e8151db18795",
    measurementId: "G-73KJMTRZGL"
  };

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export default db;