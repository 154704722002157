import { Padding } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { height } from '@mui/system';

const kbgStyles = makeStyles((theme) => ({
    kbgMain: {
        display: 'flex',
        '@media (max-width:768px)': {
            flexWrap: 'wrap',
        },
        width: '100%',
        height: window.screen.height
    },
    kbgImageDiv: {
        width: '45%',
        '@media (max-width:768px)': {
            width: '100%'
        }
    },
    kbgImage: {
        width: '100%',
        height: window.screen.height,
        '@media (max-width:768px)': {
            width: '100%'
        }
    },
    kbgContent: {
        background: "linear-gradient(349deg, rgba(228,81,253,1) 19%, rgba(9,27,140,1) 92%, rgba(253,254,252,1) 100%)",
        width: '100%',
    },
    kbgContentCenterDiv: {
        maxWidth: '500px',
        margin: '50px auto',
        textAlign: 'center',
    },
    kbgContentH2: {
        margin: '10px 0px',
        color: '#fdcd0c'
    },
    kbgContentH1: {
        margin: '15px 0px',
        color: '#feffff'
    },
    kbgContentP: {
        margin: '15px auto',
        color: '#feffff',
        maxWidth: '350px',
        textAlign: 'center',
        lineHeight: '30px',
        fontSize: '20px'
    },
    kbgContentButton: {
        backgroundColor: '#f3c100',
        color: '#feffff',
        height: '50px',
        width: '150px',
        textAlign: 'center',
        borderRadius:'30px',
        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
        border: 'none',
        fontWeight: '600',
        fontSize: '20px',
        fontFamily: 'Roboto',
        transition: 'all 0.3s ease 0s',
        cursor: 'pointer',
        outline: 'none',
        margin:'10px',
        padding:'5px'
    }
}));

export default kbgStyles;
