import React, { useState, useEffect } from 'react';
import hemsrutiStyles from './hemsrutiStyles';
import hemsrutiImage1 from '../assets/images/hemsruti/hemsruti1.jpeg'
import hemsrutiImage2 from '../assets/images/hemsruti/hemsruti2.jpeg'
import hemsrutiImage3 from '../assets/images/hemsruti/hemsruti3.jpeg'


const Hemsruti = () => {
    const classes = hemsrutiStyles();

    const images = [hemsrutiImage1, hemsrutiImage2, hemsrutiImage3]
    return <>
        <div className={classes.hemsrutiMain}>
            {images.map((obj) => <div className={classes.hemsrutiImageDiv}>
                <img src={obj} className={classes.hemsrutiImage} alt="jbgImage" />
            </div>)}
        </div>
    </>
}

export default Hemsruti
