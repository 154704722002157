import { makeStyles } from '@mui/styles';

const navbarStyle = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    navlinks: {
        marginLeft: 10,
        display: "flex",
        justifyContent: 'center',
        '@media (max-width:768px)': {
            display: 'none'
        }
    },
    hamburger: {
        float: 'left',
    },
    mainNavbar: {
        alignItems: 'center',
        backgroundColor: '#cc9b7e',
        display: 'flex',
        justifyContent: 'center',
        '@media (max-width:768px)': {
            alignItems: 'start'
        }
    },
    logo: {
        flexGrow: "1",
        cursor: "pointer",
    },
    link: {
        textDecoration: "none",
        color: "white",
        fontSize: "20px",
        marginLeft: 20,
        textAlign: 'center',
        "&:hover": {
            color: "yellow",
            borderBottom: "1px solid white",
        },
        '@media (max-width:425px)': {
            fontSize: '15px',
        },
    },
    navButton: {
        backgroundColor: '#f3c100',
        color: '#feffff',
        height: '30px',
        width: '150px',
        textAlign: 'center',
        borderRadius: '30px',
        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
        border: 'none',
        fontWeight: '500',
        fontSize: '16px',
        fontFamily: 'Roboto',
        transition: 'all 0.3s ease 0s',
        cursor: 'pointer',
        outline: 'none',
        margin:'0px 15px',
        padding: '5px'
    }
}));


export default navbarStyle;
