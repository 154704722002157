import { makeStyles } from '@mui/styles';
import { margin, padding } from '@mui/system';

const homePageStyles = makeStyles((theme) => ({
    homepageWrapper: {
        width: '100%',
        margin: 'auto',
    },
    banner: {
        width: '100%',
    },
    H4: {
        textAlign: 'center',
        color: 'blue',
        fontWeight: 'bold',
        fontFamily: 'Hind Vadodara!important',
    },
    paragraph1: {
        display: 'block',
        textAlign: 'center',
        fontSize: '25px',
        fontFamily: 'Hind Vadodara!important',
    },
    paragraph2: {
        display: 'block',
        textAlign: 'right',
        fontSize: '25px',
        fontFamily: 'Hind Vadodara!important',
    },
    carousel: {
        height: '15px',
        display: 'block',
        backgroundColor: 'black',
    },
    Title: {
        textAlign: 'center',
        color: 'blue',
        fontFamily: 'Hind Vadodara!important',
    },
    aboutAmbiyl_Atham: {
        display: 'flex',
        margin: '5px',
        '@media (max-width:425px)': {
            flexDirection: 'column'
        },
    },
    Ambiyl_Atham: {
        backgroundColor: '#FFFCFA',
        margin: '10px',
        fontSize: '25px',
        borderRadius: '20px',
        padding: '15px',
        boxShadow: '0px 0px 15px grey',
        textAlign: 'center',
        flex: '0 0 48%'
    },

    photoParagraph: {
        margin: '10px',
        '@media (max-width:425px)': {
            flexDirection: 'column'
        },
    },
    photoContent: {
        margin: 'auto',
        width: '90%'
    },

    paragraphContent: {
        backgroundColor: '#FFFCFA',
        flex: '3',
        margin: '10px',
        fontSize: '25px',
        borderRadius: '20px',
        padding: '15px',
        boxShadow: '0px 0px 15px grey',
        textAlign: 'center',

    },
    footers: {
        margin: '10px',
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        color: 'white',
        '@media (max-width:425px)': {
            flexDirection: 'column'
        },

    },
    footer: {
        marginRight: '6px',
        background: 'linear-gradient(0deg, rgba(152,92,64,1) 0%, rgba(133,83,60,1) 35%, rgba(15,14,14,1) 100%);',
        color: 'white',
        width: '100%'
    },
    footerContent: {
        fontSize: '20px',
        fontFamily: 'Hind Vadodara!important',
    },
    logoText: {
        display: 'flex'
    },
    videoFrame: {
        display: 'flex',
        margin: '10px 20px',
        flexWrap: 'nowrap',
        overflow: 'auto'
    },
    eachFrame: {
        margin: '5px',
    }


}));

export default homePageStyles;
