import React from 'react'
import registerStyles from './registerStyles';

export const Register = () => {
  const classes = registerStyles();

  return (
    <>
      <div className={classes.main}>
        <iframe className={classes.form} src="https://docs.google.com/forms/d/e/1FAIpQLSf3KflponCjkYB9-6OsFXNZxYz7qSqThz3h884rwhVhJkkr7Q/viewform?embedded=true" frameborder="0" >Loading…</iframe>
      </div>
    </>

  )
}
