import { Carousel } from '3d-react-carousal';

import Page_7 from '../assets/images/carousel/Page_7.png'
import Page_8 from '../assets/images/carousel/Page_8.png'
import Page_10 from '../assets/images/carousel/Page_10.png'
import Page_11 from '../assets/images/carousel/Page_11.png'
import Page_12 from '../assets/images/carousel/Page_12.png'
import Page_16 from '../assets/images/carousel/Page_16.png'
import Page_17 from '../assets/images/carousel/Page_17.png'
import Page_39 from '../assets/images/carousel/Page_39.png'
import Page_40 from '../assets/images/carousel/Page_40.png'
import Page_42 from '../assets/images/carousel/Page_42.png'
import Page_55 from '../assets/images/carousel/Page_55.png'
import Page_61 from '../assets/images/carousel/Page_61.jpg'

const Carousel3d = () => {
    let slides = [
        <img src={Page_7} alt="1" height={550} />,
        <img src={Page_8} alt="1" height={550} />,
        <img src={Page_10} alt="1" height={550} />,
        <img src={Page_11} alt="1" height={550} />,
        <img src={Page_12} alt="1" height={550} />,
        <img src={Page_16} alt="1" height={550} />,
        <img src={Page_17} alt="1" height={550} />,
        <img src={Page_39} alt="1" height={550} />,
        <img src={Page_40} alt="1" height={550} />,
        <img src={Page_42} alt="1" height={550} />,
        <img src={Page_55} alt="1" height={550} />,
        <img src={Page_61} alt="1" height={550} />,
    ]
    return <Carousel slides={slides} autoplay={false} style={{ backgroundColor: 'black' }} />
}

export default Carousel3d
