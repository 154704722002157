import { makeStyles } from '@mui/styles';

const hemsrutiStyles = makeStyles((theme) => ({
    hemsrutiMain: {
        display: 'flex',
        flexWrap:'wrap',
        '@media (max-width:768px)': {
            flexWrap: 'wrap',
        },
        width: '100%',
        backgroundColor:'#cda57c'
    },
    hemsrutiImageDiv: {
        margin: '0px auto',
        width: '49%',
        '@media (max-width:768px)': {
            width: '100%'
        }
    },
    hemsrutiImage: {
        width: '100%',
        height: '90vh',
        '@media (max-width:768px)': {
            width: '100%'
        }
    },
}));

export default hemsrutiStyles;
