import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import db from '../firebaseConfig';
import { DataGrid } from '@mui/x-data-grid';
// import listPageStyles from './listPageStyles';

const columns = [
    { field: 'id', headerName: 'Id', width: 10 },
    { field: 'date', headerName: 'Date', width: 200 },
    { field: 'email', headerName: 'Email', width: 300 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'location', headerName: 'Location', width: 200 },
    { field: 'whatsapp', headerName: 'Whatsapp', width: 300 },
];

const AtthamList = () => {
    // const classes = listPageStyles();
    const [AtthamData, setAtthamData] = useState([])
    const [count, setCount] = useState()

    function exportJSONToCSV(objArray) {
        var arr = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        var str = `${Object.keys(arr[0]).map((value) => `"${value}"`).join(',')}` + '\r\n';
        var csvContent = arr.reduce((st, next) => {
            st += `${Object.values(next).map((value) => `"${value}"`).join(',')}` + '\r\n';
            return st;
        }, str);
        var element = document.createElement('a');
        element.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
        element.target = '_blank';
        element.download = 'export.csv';
        element.click();
    }

    const getData = async () => {
        var dataArray = []
        await db.collection('attham')
            .get()
            .then((results) => {
                results.docs.forEach((data, i) => {
                    const sendData = {
                        id: dataArray.length + 1,
                        date: data.id,
                        email: Object.keys(data.data())[0],
                        name: data.data()[Object.keys(data.data())[0]][0].name,
                        location: data.data()[Object.keys(data.data())[0]][0].location,
                        whatsapp: data.data()[Object.keys(data.data())[0]][0].whatsapp,
                    }
                    dataArray.push(sendData)
                })
            })
        await db.collection('AtthamOther')
            .get()
            .then((results) => {
                results.docs.forEach((data, i) => {
                    const sendData = {
                        id: dataArray.length + 1,
                        date: data.data().day,
                        email: data.data().email,
                        name: data.data().name,
                        location: data.data().location,
                        whatsapp: data.data().whatsapp,
                    }
                    dataArray.push(sendData)
                })
            })
        setCount(dataArray.length)
        setAtthamData([...dataArray])
    }
    useEffect(() => {
        getData()
    }, [])
    return <>
        <Box>
            <div styles={{ margin: '5px' }}>
                <Button variant="contained" color="primary" onClick={() => { exportJSONToCSV(AtthamData) }}>Download CSV</Button>
            </div>
            <Typography align="center" mt={5} mb={5}>
                Total Attham List Count- <b>{count}/118</b>
            </Typography>
            <div style={{ margin: 'auto', height: 2700, width: '90%' }}>
                <DataGrid
                    rows={AtthamData}
                    columns={columns}
                    pageSize={50}
                    rowsPerPageOptions={[50]}
                />
            </div>

        </Box>
    </>
}

export default AtthamList
