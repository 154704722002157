import React from 'react';
import banner from '.././assets/images/banner.jpeg'
import vfwlogo from '.././assets/images/vfwlogo.png'
import { Link } from "react-router-dom";
import { Box, Typography, Button, Stack, Card, CardContent } from '@mui/material';
import homePageStyles from './homePageStyles';
import MyCarousel from './Carousel3d';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import DownloadIcon from '@mui/icons-material/Download';

const videosLink = ['https://www.youtube.com/embed/k0x09HQ8s_Q', 'https://www.youtube.com/embed/E1VA3xuJVqM', 'https://www.youtube.com/embed/p-8x-fxzUn4']
const Homepage = () => {
    const classes = homePageStyles();

    return <>
        <Box className={classes.homepageWrapper}>
            <Box >
                <img className={classes.banner} src={banner} alt="banner" />
            </Box>
            <Typography variant="h4" className={classes.H4} m={2}> વિશ્વવત્સલ વૈરાગ્યદેશનાદક્ષ પ્રાચીન શ્રુતોદ્ધારક
                સદ્દગુરુ શ્રી હેમચન્દ્રસૂરીશ્વરજી મહારાજા
                સમાધિ મંદિર – પ્રતિષ્ઠા</Typography>
            <Typography variant="paragraph" m={3} className={classes.paragraph1} >
                વૈરાગ્યદેશનાદક્ષ પૂજ્યપાદ આચાર્ય શ્રી હેમચન્દ્રસૂરીશ્વરજી મહારાજના સમાધિ મંદિરમાં ગુરૂમૂર્તિની મંગલકારી પ્રતિષ્ઠા નિમિત્તે ૧ વર્ષ સુધી સાંકળી અઠ્ઠમ અને સાંકળી આયંબિલનું સુંદર આયોજન કરેલ છે. પૂજ્યપાદ ગુરુદેવશ્રી સાથે આપણો ઋણાનુંબંધ બંધાય ...
                ગુરુદેવશ્રીના ગુણસિંધુમાંથી એકાદ બિંદુની પ્રાપ્તિ થાય ...
                ગુરુદેવશ્રીનું સ્વપ્ન <b style={{ color: 'green' }}>“હિંસા મુક્ત વિશ્વ”</b> સાકાર બને એવી મંગલભાવના સાથે સાંકળી અઠ્ઠમ અને સાંકળી આયંબિલમાં જોડાવા સૌને ભાવભીનું આમંત્રણ પાઠવીએ છીએ.<br />

            </Typography>
            <Typography variant="paragraph" className={classes.paragraph2} m={5} >-શ્રી જિનશાસન આરાધના ટ્રસ્ટ</Typography>
            <div className={classes.videoFrame}>
                {
                    videosLink.map((obj) => <>
                        <iframe width="300" height="215" className={classes.eachFrame}
                            src={obj}>
                        </iframe></>)
                }
            </div>
            <Typography variant="paragraph" m={3} className={classes.paragraph1}>
                <u><h3>આમંત્રણ સાંવત્સરિક સાંકળી અઠ્ઠમ, સાંકળી આયંબિલ અને જાપસાધના</h3></u>
                મંગળ પ્રારંભ - માગસર સુદ ૧૦, વિક્રમ સંવત ૨૦૭૮ <br />
                મંગળ પૂર્ણાહૂતિ - માગસર સુદ ૧૦, વિક્રમ સંવત ૨૦૭૯<br />
                (૧૩-૧૨-૨૧  થી ૨-૧૨-૨૦૨૨)<br />


                <h3><u>મંગળ સંકલ્પ</u></h3> <b>‘હિંસા મુક્ત વિશ્વ’</b> <br /> સમસ્ત વિશ્વ સમસ્ત હિંસાથી મુક્ત બની જાય<br /> તથા ‘शिवमस्तु सर्वजगत:’ સમસ્ત વિશ્વનું કલ્યાણ થાય.

                <h3><u>મંગળ પરંપરા</u></h3> સદ્દગુરુની હ્રદય પ્રતિષ્ઠા, સદ્દગુરુની અનરાધાર કૃપા વર્ષા,<br /> ક્લિષ્ટ કર્મોનો ક્ષય, વિઘ્ન વિનાશ, શાતા વેદનીય ઉપાર્જન, <br /> સુખ, શાંતિ, સમૃદ્ધિ, સમાધિ, સદ્દગતિ અને મોક્ષ

            </Typography>
            <Box className={classes.aboutAmbiyl_Atham}>
                <Box className={classes.Ambiyl_Atham}>
                    <Typography variant="h4" className={classes.Title}>સાંકળી અઠ્ઠમ પ્રારંભ</Typography>
                    <Typography variant="paragraph" className={classes.paragraph1}>
                        પ્રારંભ - માગસર સુદ ૧૦, વિક્રમ સંવત ૨૦૭૮ <br />પૂર્ણાહૂતિ - માગસર સુદ ૧૦, વિક્રમ સંવત ૨૦૭૯<br />
                        (૧૩-૧૨-૨૦૨૧	થી 	૨-૧૨-૨૦૨૨)<br />
                    </Typography>
                    <br />
                    <Link to="/attham" style={{ textDecoration: 'none' }} >
                        <Button className={classes.button} style={{
                            borderRadius: 20,
                            background: 'radial-gradient(circle, rgba(152,92,64,1) 0%, rgba(133,83,60,1) 35%, rgba(15,14,14,1) 100%)',
                            padding: "10px 10px",
                            color: 'white',
                            textAlign: 'center',
                        }} >Book Your Attham</Button>
                    </Link>

                </Box>
                <Box className={classes.Ambiyl_Atham}>
                    <Typography variant="h4" className={classes.Title}>સાંકળી આયંબિલ</Typography>

                    <Typography variant="paragraph" className={classes.paragraph1} >
                        પ્રારંભ - માગસર સુદ ૧૦, વિક્રમ સંવત ૨૦૭૮ <br />પૂર્ણાહૂતિ - માગસર સુદ ૧૦, વિક્રમ સંવત ૨૦૭૯<br />

                        (૧૩-૧૨-૨૦૨૧	થી ૨-૧૨-૨૦૨૨)<br />

                    </Typography>
                    <br />
                    <Link to="/ayambil" style={{ textDecoration: 'none' }} >
                        <Button style={{
                            borderRadius: 20,
                            background: 'radial-gradient(circle, rgba(152,92,64,1) 0%, rgba(133,83,60,1) 35%, rgba(15,14,14,1) 100%)',
                            padding: "10px 10px",
                            color: 'white',
                        }}  >Book Your Ayambil</Button>
                    </Link>
                </Box>
            </Box>
            <Box >
                <MyCarousel className={classes.carousel} />

            </Box>
            <br />
            <Typography variant="paragraph" mt={5} mb={5} mx={10} className={classes.paragraph1}>
                "નોંધ : ફોનથી અઠ્ઠમ અને આયંબિલની સાંકળી આરાધનામાં જોડાવા સંપર્ક કરો - ૭૦૪૩૧૭૨૨૮૭"<br />
            </Typography>

            <Typography variant="h4" mt={5} ms={2} className={classes.H4}>
                આમંત્રક અને બહુમાનકર્તા<br />
            </Typography>
            <Typography variant="paragraph" mb={3} mx={2} className={classes.paragraph1}>
                શ્રી જિનશાસન આરાધના ટ્રસ્ટ<br />
                સમાધિમંદિર, પ્રેમકુંજ, પરિમલ, અમદાવાદ<br />
            </Typography>

            <Typography variant="paragraph" mb={3} className={classes.paragraph1}>
                <b>આયંબિલતપ ના લાભાર્થી :</b> રજનીકાંત કાંતિલાલ શાહ, રાજાબાબુના સ્મરણાર્થે.
            </Typography>

            <Box className={classes.footers}>
                <Card className={classes.footer} >
                    <CardContent style={{ color: 'white' }} >

                        <Typography variant="h4" style={{ textAlign: 'center', textDecoration: 'underline' }} component="div">
                            Latest Books
                        </Typography>
                        <Typography variant="body" className={classes.footerContent}>
                            <ul>
                                <li> વૈરાગ્યદેશનામાં હજી પણ દક્ષ</li>
                                <li><u><span style={{ cursor: 'pointer', color: 'white' }} onClick={() => window.open('https://drive.google.com/file/d/1KxNUkeIDaR7QEe_mciPSY-3uYBPij7GS/view?usp=sharing', '_blank')} >Download Book</span></u><DownloadIcon /> </li>
                            </ul>
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={classes.footer} >
                    <CardContent style={{ color: 'white' }} >
                        <Typography variant="h4" style={{ textAlign: 'center', textDecoration: 'underline' }} component="div">
                            Samadhi Mandir Address
                        </Typography>
                        <Typography variant="body" className={classes.footerContent}>
                            <ul style={{ listStyleType: 'none' }}>
                                <li>શ્રી જિનશાસન આરાધના ટ્રસ્ટ<br />
                                    સમાધિમંદિર, પ્રેમકુંજ, પરિમલ, અમદાવાદ<br /></li>
                                <li>Call: +91 9875030336</li>
                            </ul>
                        </Typography>
                    </CardContent >
                </Card>
                <Card className={classes.footer} >
                    <CardContent style={{ color: 'white' }} >
                        <div className={classes.logoText}> <img style={{ textAlign: 'center' }} src={vfwlogo} height={120} widht={120} alt="logo" />
                            <Typography variant="h4" style={{ textAlign: 'center', textDecoration: 'underline' }} component="div">
                                Violence Free World
                            </Typography></div>

                        <Typography variant="body" className={classes.footerContent}>
                            <ul style={{ listStyleType: 'none' }}>
                                <li>Pray for at least 1 minute for everyday</li>
                                <li>"Let the World be free from violence"</li>
                                <li>Lakhs and Crores of People Praying</li>
                                <li>Will bring the World - eternal peace and kindness.</li>
                                <li style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.facebook.com/vfwlife/')} >visit: <span style={{ color: 'lightgreen' }}>www.vfw.life</span></li>
                                <li><Stack spacing={2} direction="row"><FacebookIcon style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.facebook.com/vfwlife/')} /><TwitterIcon style={{ cursor: 'pointer' }} onClick={() => window.open('https://twitter.com/violencefreewo1')} /><InstagramIcon style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.instagram.com/violencefreeworld_vfw/')} /></Stack></li>
                            </ul>
                        </Typography>
                    </CardContent>
                </Card>
            </Box >
        </Box >
    </>
}

export default Homepage
