import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Ayambil from './pages/Ayambil';
import Attham from './pages/Attham';
import HomePage from './pages/HomePage';
import Navbar from './components/Navbar';
import './App.css';
import AyambilList from "./pages/AyambilList";
import AtthamList from "./pages/AtthamList";
import { Register } from "./pages/Register";
import Kbg from "./pages/kbg";
import Nrutyam from "./pages/Nrutyam";
import Hemsruti from "./pages/Hemsruti";
import Arihant from "./pages/Arihant";

const App = () => {
  return (
    <div style={{ backgroundColor: '#f9f9f9' }}>
      <BrowserRouter>
        <ToastContainer />
        <Navbar />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/ayambil" component={Ayambil} />
          <Route exact path="/attham" component={Attham} />
          <Route exact path="/ayambil-list" component={AyambilList} />
          <Route exact path="/attham-list" component={AtthamList} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/kbg" component={Kbg} />
          <Route exact path="/nrutyam" component={Nrutyam} />
          <Route exact path="/hemsmruti" component={Hemsruti} />
          <Route exact path="/arihant" component={Arihant} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
