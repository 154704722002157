import { makeStyles } from '@mui/styles';

const registerStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        justifyContent: 'center',
        '@media (max-width:425px)': {
            justifyContent: 'unset'
        },
    },
    form: {
        width: "100%",
        height: "1700px",
    },
}));

export default registerStyles;
