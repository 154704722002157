import { makeStyles } from '@mui/styles';

const nrutyamStyles = makeStyles((theme) => ({
    nrutyamMain: {
        display: 'flex',
        '@media (max-width:768px)': {
            flexWrap: 'wrap',
        },
        width: '100%',
        height: window.screen.height,
        backgroundColor: '#cda57c'
    },
    nrutyamImageDiv: {
        width: '50%',
        '@media (max-width:768px)': {
            width: '100%'
        }
    },
    nrutyamImage: {
        width: '100%',
        height: window.screen.height,
        '@media (max-width:768px)': {
            width: '100%'
        }
    },
    nrutyamButton: {
        position: 'fixed',
        bottom: '10px',
        right: '20px',
        backgroundColor: '#f3c100',
        color: '#feffff',
        height: '50px',
        width: '150px',
        textAlign: 'center',
        borderRadius: '30px',
        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
        border: 'none',
        fontWeight: '600',
        fontSize: '20px',
        fontFamily: 'Roboto',
        transition: 'all 0.3s ease 0s',
        cursor: 'pointer',
        outline: 'none',
        margin: '10px',
        padding: '5px',
        '@media (max-width:768px)': {
            fontSize: '16px',
            margin: '8px',
            padding: '3px',
            height: '40px',
            width: '100px',
        }
    }
}));

export default nrutyamStyles;
