import { makeStyles } from '@mui/styles';

const arihantStyles = makeStyles((theme) => ({
    main: {
        textAlign: 'center',
    },
    list: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    listEach: {
        margin: '5px'
    }
}));

export default arihantStyles;
