import * as React from 'react';
import navbarStyle from './navbarPageStyle';
import { Link, useHistory } from "react-router-dom";

import {
    AppBar,
    Toolbar,
    CssBaseline,
    Button,
    ListItemText,
    ListItemButton,
    Divider,
    Drawer,
    IconButton,
} from '@mui/material';
import { Box } from '@mui/system';
import { GridCloseIcon, GridMenuIcon } from '@mui/x-data-grid';

export default function Navbar() {
    const classes = navbarStyle();
    const history = useHistory()
    const [open, setState] = React.useState(false);

    const routes = [
        { text: 'Home', linkTo: '/' },
        { text: 'KBG', linkTo: '/kbg' },
        { text: 'Nrutyam', linkTo: '/nrutyam' },
        { text: 'हेमस्मृति', linkTo: '/hemsmruti' },
        { text: 'Book Attham', linkTo: '/attham' },
        { text: 'Book Ayambil', linkTo: '/ayambil' },
        { text: 'Register', linkTo: '/register' },
    ]

    //function that is being called every time the drawer should open or close, the keys tab and shift are excluded so the user can focus between the elements with the keys
    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        //changes the function state according to the value of open
        setState(open);
    };

    const viewPatrika = () => {
        window.open('https://jainsangh.s3.ap-south-1.amazonaws.com/hemsuriFiles/patrika.pdf', '_blank')
    }

    return (
        <div className={classes.root}>
            <AppBar className={classes.mainNavbar} style={{ backgroundColor: '#985c40' }} position="static">
                <CssBaseline />
                <Toolbar>
                    <div className={classes.navlinks}>
                        {
                            routes.map((obj) => <>
                                <Link to={obj.linkTo} className={classes.link}>
                                    {obj.text}
                                </Link></>)
                        }
                        <button className={classes.navButton} onClick={viewPatrika}>
                            View Patrika
                        </button>
                    </div>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer(true)}
                        sx={{
                            mr: 2,
                            float: 'right',
                            display: 'none',
                            '@media (max-width:768px)': {
                                display: 'block'
                            }
                        }}
                    >
                        <GridMenuIcon />
                    </IconButton>
                    <Drawer
                        //from which side the drawer slides in
                        anchor="left"
                        //if open is true --> drawer is shown
                        open={open}
                        //function that is called when the drawer should close
                        onClose={toggleDrawer(false)}
                        //function that is called when the drawer should open
                        onOpen={toggleDrawer(true)}
                    >
                        {/* The inside of the drawer */}
                        <Box
                            sx={{
                                p: 2,
                                height: 1,
                                backgroundColor: "#dbc8ff"
                            }}
                        >
                            {/* when clicking the icon it calls the function toggleDrawer and closes the drawer by setting the variable open to false */}
                            <IconButton sx={{ mb: 2 }}>
                                <GridCloseIcon onClick={toggleDrawer(false)} />
                            </IconButton>

                            <Divider sx={{ mb: 2 }} />

                            <Box sx={{ mb: 2 }}>
                                {
                                    routes.map((obj) => <><ListItemButton onClick={() => { history.push(obj.linkTo) }}>
                                        <ListItemText primary={obj.text} onClick={toggleDrawer(false)} />
                                    </ListItemButton></>)
                                }
                                <button className={classes.navButton} onClick={viewPatrika}>
                                    View Patrika
                                </button>
                            </Box>
                        </Box>
                    </Drawer>
                </Toolbar>
            </AppBar>
        </div>

    );


}
